<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv kv-top-contain">
        <div class="uk-container">
          <div class="uk-grid uk-margin-bottom uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-1-2">
              <div class="logo-plane">
                <img src="@/assets/img/logo.svg" alt="MEta Clone"/>
              </div>
            </div>
          </div>
          <div style="display: grid;">
            <div>
              <button type="button" class="btn menu" style="position: absolute;right: 15px;top: 40px;right: 30px;" @click="signOut">サインアウト</button>
            </div>
          </div>
          <div v-if="isViewPage()" class="uk-margin uk-card uk-card-small uk-card-secondary uk-card-body">
            <h3 class="view-caution">本ページはお客様への共有目的のため認証を設けておりません。そのため、本ページURLを<span class="uk-text-bold">SNS等に投稿しないようお願い申し上げます</span>。</h3>
          </div>
          <div class="white-dot-bg" style="margin: 30px auto;padding-bottom: 30px;">
            <created-avatar ref="created-avatar" :isViewPage="isViewPage()"></created-avatar>
            <div class="dashed"></div>
            <div class="btn-wrapper" :class="isSmartPhone ? 'uk-margin-large-top uk-margin-medium-bottom' : 'uk-margin-medium-top'">
              <router-link :to="routePreview" class="btn no">顔を選び直す</router-link>
              <button v-if="!isVRMDownload" class="btn yes" style="margin-left: 15px;" :disabled="is_processing" @click="confirm('normal')"><img src="@/assets/img/icon_create.svg">ダウンロード</button>
              <button v-if="isVRMDownload" class="btn yes" style="margin-left: 15px;" @click="confirm('vrm')"><img src="@/assets/img/icon_create.svg">ダウンロード</button>
            </div>
            <div v-if="admin">
              <div v-if="export_format == 'fbx' && !isVRMDownload" class="btn yes other vrm mt-3" :disabled="is_processing" @click="confirm('vrm')" >
                <img src="@/assets/img/icon_create.svg">FBXをVRMに変換してダウンロード<span class="display-pc">（※3分程かかります）</span>
              </div>
              <!-- 2023年9月 不具合があるので一時的に使えないようにした
              <div @click="confirm('glb2vrm')" v-if="export_format == 'glb' && metaKind == 'meta'" class="btn yes other mt-3">
                <img src="@/assets/img/icon_create.svg">GLBをVRMに変換してダウンロード
              </div>
              -->
              <div @click="routeView()" class="btn yes other mt-3">顧客共有用ページへ移動する</div>
              <div @click="routeAnimation()" class="btn yes other mt-3">アニメーションデモを確認する</div>
            </div>
            <div class="btn-wrapper">
              <router-link to="/" class="btn no other mt-3">トップに戻る</router-link>
            </div>
            <div class="limit-wrapper">
              <div v-if="!admin && (account_manage_info.item.kind == 'subscription' || !account_manage_info.item.kind)">
                <div class="limit-title">今月のダウンロード数 : {{ download_count["subscription"] }}/ {{account_manage_info.item.plan || download_count_info.limit}}</div>
                <div class="limit-title sm mt-2">ダウンロード数の更新は毎月{{ new Date(account_manage_info.item.plan_start).getDate() }}日です</div>
              </div>
              <div v-else-if="!admin && account_manage_info.item.kind == 'all_span'">
                <div class="limit-title">ダウンロード数: {{ download_count["all_span"] }}/{{ account_manage_info.item.plan }}</div>
                <div class="limit-title mt-2">利用期限: {{ dateFormat2(new Date(account_manage_info.item.plan_end), -1, 0) }}</div>
              </div>
              <div v-else>
                <div class="limit-title">ダウンロード数 : {{download_count["admin"]}}</div>
              </div>
            </div>
            <div id="loading-wrapper" :class="is_processing ? '' : 'uk-invisible'">
              <div id="loading-text" class="uk-position-center">処理中</div>
              <div id="loading-content" class="uk-position-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-show="isShowConfirm" :border="'1px solid #aaa'" :modalColor="'rgb(54,54,54)'" :donetext="'ダウンロード'" :canceltext="'いいえ'" :propWidth="'650px !important'" @modal-done="isShowConfirm=false;download()" @modal-close="isShowConfirm=false">
      <template v-slot:body>
        <div style="color:white;text-align: center;">
          <div class="large-font" v-html="confirmTitle"></div>
          <div class="mt-3">
            一度ダウンロードしたアバターは再度ダウンロードしてもカウントされません。<br/>
            <span v-if="account_manage_info.item.kind == 'all_span'">
              ダウンロード数 : <span class="large-font">{{ download_count['all_span'] }}</span>
              ( 契約期間 :
                {{ dateFormat2(new Date(account_manage_info.item.plan_start), 0, 0) }}
                〜
                {{ dateFormat2(new Date(account_manage_info.item.plan_end), -1, 0) }} )
            </span>
            <template v-else>
              今月のダウンロード数 : <span class="large-font">{{ download_count["subscription"] }}/ {{account_manage_info.item.plan || download_count_info.limit}}</span>
              （ダウンロード数の更新は毎月{{resetDate}}日です。）
            </template>
            <p v-if="kind === 'vrm' && isNormalMap" class="normalmap-notice">※ アップロードする環境によって Normal Map が適用されない場合があります。( Cluster 等 )</p>
          </div>
        </div>
      </template>
    </modal>
    <modal v-show="isOverLimit" :canceltext="'OK'" :propWidth="'600px !important'" @modal-close="isOverLimit=false">
      <template v-slot:body>
        <div style="color:white;text-align: center;">
          <div style="font-size:1.2em;">
            <div>ダウンロード上限数に達しました。</div>
            <div style="font-size: 0.8em;margin-top:7px;">追加で制作したい場合は営業までご連絡ください。</div>
          </div>
        </div>
      </template>
    </modal>
    <modal v-show="isShowFilename" :modalColor="'rgb(204,204,204)'" :color="'black'" :header="'ファイル名編集'" :donetext="'ダウンロード'" :propWidth="'650px !important'" @modal-done="postProcess">
      <template v-slot:body>
        <input type="text" class="form-control2" v-model="fileName"/>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CreatedAvatar from '@/components/CreatedAvatar.vue'
import Modal from '@/components/parts/Modal.vue'
import { Auth } from "aws-amplify";

export default {
  name: 'Result',
  components: {
    CreatedAvatar,
    Modal,
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      export_format: '',
      kind: 'normal',  // normal:通常ダウンロード, vrm: VRM変換ダウンロード
      metaKind: '',  // meta(MetaPerson), fit(FitPerson)
      fileName: '',
      resetDate: 0,
      blob: undefined,
      month_str: `${new Date().getFullYear()}-${new Date().getMonth()+1}`,
      download_count: {subscription:0, admin:0, all_span:0},
      download_count_info: {status:'', limit:0, item:{}},
      account_manage_info: {status:'', item:{}},
      confirmTitle: '',
      admin: false,  // 管理者かどうか
      is_processing: false,
      isVRMDownload: false,
      isShowConfirm: false,
      isOverLimit: false,
      isShowFilename: false,
      isNormalMap: true,
      dlDirName: '',
    }
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) return true
      return false
    },
    routePreview() {
      return `/preview/${this.uuid}`
    },
  },
  methods: {
    isViewPage() {
      if ( this.$route.path.match(/\/view/)) return true
      return false
    },
    async signOut() {
      try {
        await Auth.signOut()
      } catch (error) {
        console.log('error signing out: ', error)
      }
    },
    dateFormat(month_str){
      const split = month_str.split('-')
      return `${split[0]}年${split[1]}月`
    },
    dateFormat2(date, diff, diff_month){
      date.setMonth(date.getMonth() + diff_month)
      date.setDate(date.getDate() + diff)
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth()+1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}/${month}/${day}`
    },
    dateFormat3(date){
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth()+1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      const hours = ('0' + date.getHours()).slice(-2)
      const minutes = ('0' + date.getMinutes()).slice(-2)
      const seconds = ('0' + date.getSeconds()).slice(-2)
      return `${year}${month}${day}${hours}${minutes}${seconds}`
    },
    async admin_sub(callback){
      this.getRequest().then((req) => {
        callback(req.user_sub)
      })
    },
    download_impl(sub, kind) {
      if (sub == undefined) return
      const info = this.download_count_info.item
      const isTarget = (info && info[sub] && info[sub][kind]) ? true : false
      if (!isTarget) return
      let item
      if (kind == 'subscription') {
        let now = new Date()
        if (now.getDate() < new Date(this.account_manage_info.item.plan_start).getDate()) {
          now.setMonth(now.getMonth() - 1)
          this.month_str = `${now.getFullYear()}-${now.getMonth()+1}`
        }
        item = info[sub][kind].filter(v => v.month_str.replace(/-0/, '-') == this.month_str)[0]
      } else {
        item = info[sub][kind]
      }
      const cnt = item ? item['download_count'] : 0
      const meta_cnt = item ? item['meta_download_count'] : 0
      this.download_count[kind] = cnt + meta_cnt
    },
    download_num(kind='pre') {
      if (this.admin) {
        if (kind != 'admin') return
        this.admin_sub((user_sub) => {
          this.download_impl(user_sub, 'admin')
        })
      } else {
        const sub = this.account_manage_info.item.sub ? this.account_manage_info.item.sub : this.account_manage_info.item.user_sub
        this.download_impl(sub, kind)
      }
    },
    confirm(kind){
      this.kind = kind
      this.confirmTitle = ''
      this.isNormalMap = this.$refs['created-avatar'].isNormalMap
      if (this.admin) {
        this.download()
        return
      }
      let downloaded = 0
      if (this.account_manage_info.item.kind == 'all_span') {
        downloaded = this.download_count['all_span']
      } else {
        downloaded = this.download_count['subscription']
      }
      if (this.account_manage_info.item.plan <= downloaded && this.admin) {
        this.isOverLimit = true
        return
      } else {
        this.confirmTitle = 'ダウンロード数を1回消費しますが、よろしいですか？'
      }
      this.isShowConfirm = true
    },
    async download() {
      // 選択された服装のテクスチャをディレクトリにコピーして、S3にアップロードする
      const params = {
        uuid: this.uuid,
        outfit: this.$refs['created-avatar'].outfit_str,
      }
      const res = await this.axios.post('/api/upload_selected_avartar_s3', params)
      this.dlDirName = res.data.dl_dir_name
      // ダウンロード
      if (this.kind == 'normal') {
        this.downloadImpl()
      } else if (this.kind == 'vrm') {
        this.convertAndDownloadVrm('fbx')
      } else if (this.kind == 'glb2vrm') {
        this.convertAndDownloadVrm('glb')
      }
    },
    async downloadImpl(){
      this.is_processing = true
      this.axios.get('/api/download_avatar', {
        params: {
          uniq_id: this.uuid,
          dl_dir_name: this.dlDirName,
          ...await this.getRequest(),
        },
        responseType: 'blob'
      }, {
        headers: {
          Accept: 'application/zip'
        }
      })
      .then(async (response) => {
        this.blob = new Blob([response.data], { type: 'application/zip' })
        this.fileName = 'avatar.zip'
        this.is_processing = false;
        if (this.metaKind == "meta") {
          await this.makeFileName(this.$refs['created-avatar'].formatText)
          this.isShowFilename = true
        }
        else {
          this.fileName = 'avatar_a.zip'
          await this.postProcess()
        }
      })
      .catch((err) => {
        this.is_processing = false;
        this.$router.push({ name: 'error' })
        console.log(err)
      });
    },
    routeAnimation() {
      this.$router.push({ name: 'animation' })
    },
    routeView() {
      this.$router.push({
        name: 'view',
        params: { uuid: this.uuid },
      })
    },
    async postProcess(){
      const blob = this.blob
      const fileName = this.fileName
      if (window.navigator.msSaveOrOpenBlob) {
        // for IE, Edge
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        // for Chrome , etc...
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.parentNode.removeChild(link)
      }
      const request_params = await this.getRequest()
      request_params['kind'] = this.account_manage_info.item.kind
      const response = await this.axios.post('/api/get_avatar_download_count', request_params)
      this.download_count_info = response.data
      this.countRefresh()
      this.is_processing = false
      this.isShowFilename = false
    },
    async convertAndDownloadVrm(kind='fbx') {
      this.is_processing = true
      let apiURL = '/api/convert_and_download_vrm'
      const normal_map_name = this.$refs['created-avatar'].s3_outfit_normal_map_url.split('/').pop().split('?')[0]
      let uniqParams = (this.isNormalMap) ? {uniq_id: this.uuid, dl_dir_name: this.dlDirName, normal_map_name: normal_map_name} : {uniq_id: this.uuid, dl_dir_name: this.dlDirName}
      if (kind == 'glb') {
        apiURL = '/api/convert_and_download_glb2vrm'
        uniqParams = {uniq_id: this.uuid}
      }
      this.axios.get(apiURL, {
        params: {
          ...uniqParams,
          ...await this.getRequest(),
        },
        responseType: 'blob'
      }, {
        headers: {
          Accept: 'application/octet-stream'
        }
      })
      .then(async (response) => {
        this.blob = new Blob([response.data], { type: 'application/octet-stream' })
        this.fileName = 'avatar.vrm'
        this.is_processing = false;
        await this.makeFileName('VRM形式')
        this.isShowFilename = true
      })
      .catch((err) => {
        this.is_processing = false
        this.$router.push({ name: 'error' })
        console.log(err)
      });
    },
    async makeFileName(filenameExportFormat){
      const now = this.dateFormat3(new Date())
      const c = this.$refs['created-avatar']
      const gender = c.genderText
      const outfit = c.outfitText
      const format = filenameExportFormat == 'VRM形式' ? c.formatText.replace('FBX', 'VRM') : c.formatText
      const lod = c.lodText
      const removeSmile = c.removeSmileText
      const normalMap = this.isNormalMap ? 'NormalMapあり' : 'NormalMapなし'
      if (filenameExportFormat == 'VRM形式') this.fileName = `${now}_${gender}_${outfit}_${format}_${lod}_${removeSmile}_${normalMap}.vrm`
      else this.fileName = `${now}_${gender}_${outfit}_${format}_${lod}_${removeSmile}.zip`
      this.fileName = this.fileName.replace(/（/g, '(').replace(/）/g, ')').replace(/：/g, '').replace(/ /g, '')
    },
    countRefresh() {
      setTimeout(() => {
        this.download_num('subscription')
        this.download_num('all_span')
        this.download_num('admin')
      }, 500)
    },
    ...mapActions(['getRequest', 'isAdmin'])
  },
  // CreatedAvatarの中での処理と被っているのでプロパティで渡すなり、Storeするなりしたい
  async created () {
    this.axios.get(`/api/data/${this.uuid}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (response.data.status === 'NG') {
        let message = '有効期限が切れています。再度アバターを作成してください'
        if (this.isViewPage()) {
          message = '有効期限が切れています。担当者へご一報ください。'
        }
        this.$router.push({
          name: 'error',
          params: { message: message }
          })
        return
      }
      // レスポンスが200の時の処理
      this.export_format = response.data.export_format
      this.metaKind = response.data.kind
      this.isVRMDownload = response.data.org_export_format.includes('fbx_vrm')
    })
    .catch((err) => {
      if (typeof err.xhr !== 'undefined' && typeof err.xhr.response !== 'undefined' && err.xhr.response !== '') {
        const errJson = JSON.parse(err.xhr.response)
        console.log(errJson)
      }
      this.$router.push({ name: 'error' })
    })
  },
  async mounted(){
    this.admin = await this.isAdmin()
    const cognitoInfo = await this.getRequest()
    const resAc = await this.axios.post('/api/get_user_account_info', cognitoInfo)
    this.account_manage_info = resAc.data
    if (!this.account_manage_info.item) this.account_manage_info.item = {}
    const resDl = await this.axios.post('/api/get_avatar_download_count', { kind: this.account_manage_info.item.kind, ...cognitoInfo })
    this.download_count_info = resDl.data
    this.resetDate = new Date(this.account_manage_info.item.plan_start).getDate()
    this.countRefresh()
    history.pushState(null, null, null)
    window.addEventListener('popstate', () => {location.href = '/home'}, false)
  },
}
</script>

<style scoped>
#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(34, 34, 34, .6);
  z-index: 2;
}
#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #eee;
  width: 100px;
  height: 30px;
  margin: 0;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}
#loading-content {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #F00;
}
#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0F0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}
#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00F;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}
#loading-content {
  border: 3px solid transparent;
  border-top-color: #4D658D;
  border-bottom-color: #4D658D;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}
#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #D4CC6A;
  border-bottom-color: #D4CC6A;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}
#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417C;
  border-bottom-color: #84417C;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}
@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.dashed {
  width: 700px;
  border-bottom: 3px dashed white;
  margin: 0 auto;
}
.btn-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.btn.yes {
  width: 215px;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 31px;
}
.btn.yes.other {
  width: 450px;
  font-size: 14px;
  display: block;
  line-height: 31px;
  margin: 0 auto;
}
.btn.no {
  width: 215px;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 15px !important;
  position: relative !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 31px;
}
.btn.no.other {
  width: 450px;
}
.limit-wrapper {
  width: 450px;
}
.form-control2 {
  font-size: 1.0em;
  border-radius: 7px;
  padding: 7px;
  background-color: white;
  border: 1px solid rgb(189,189,189);
  width: 100%;
}
.normalmap-notice {
  text-align: center;
  font-size: 12px;
  margin: 20px 0;
}
@media (max-width: 767px) {
  .display-pc {
    display: none;
  }
  .btn.yes {
    width: auto;
  }
  .btn.no {
    width: auto;
  }
  .btn.yes.other, .btn.no.other {
    width: 350px;
  }
  .limit-wrapper {
    width: 350px;
  }
  .uk-container {
    width: 100%;
    overflow: hidden;
  }
  #renderCanvas {
    min-height: auto;
  }
}
</style>
